<template>
  <el-dialog
    id="SelectRestrictsDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="70%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="filterString = ''"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="selectRestrictsTable"
          id="selectRestrictsTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="multiple"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="restricts"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedRestricts">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="onCancel" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { _ } from "vue-underscore";
import { bus } from "@/main";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "SelectRestricts",
  props: ["busEvent"],
  components: { AgGridVue },
  data() {
    return {
      loading: false,
      filterString: "",
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      model: {},
      initModel: {},
      selectedRestricts: null,
      waitForAnswer: false,
      keyUpTimer: null,
      filterHasChanged: false,
      gridApi: null,
      gridColumnApi: null,
      excludeForSelect: [],
      operation: "",
      noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
      },

      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: [],
    };
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || vm.title;
      this.filterString = "";
      this.excludeForSelect = data && data.excludeForSelect ? data.excludeForSelect : [];
      this.operation = data && data.operation ? data.operation : "";
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
  },
  computed: {
    restricts() {
      var rest = this.$store.getters["restricts/restrictsStoreFiltered"]({
        byName: this.filterString,
      });
      var excludeIds = this.excludeForSelect.map((ex) => ex._id);
      var excl = rest.filter((item) => excludeIds.indexOf(item._id) == -1);
      return excl;
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 72 - 48 - 16 - 48;
      // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  mounted() {},
  beforeMount() {
    var that = this;
    this.columns = [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: $tt("Назва"),
        field: "name",
        flex: 1,
      },
      {
        headerName: $tt("Тип умови"),
        field: "type",
        width: "120px",
        valueGetter: function(params) {
          return $tt(that.selectOptions.restricts.type.find((option) => option.value == params.data.type).label);
        },
      },
      {
        headerName: $tt("Дата початку"),
        field: "beginDate",
        valueGetter: function(params) {
          return new Date(params.data.beginDate).toLocaleDateString();
        },
        width: "140px",
      },
      {
        headerName: $tt("Дата закінчення"),
        field: "endDate",
        valueGetter: function(params) {
          return new Date(params.data.endDate).toLocaleDateString();
        },
        width: "140px",
      },
      {
        headerName: $tt("Структури"),
        field: "orgs",
        width: "270px",
        valueGetter: function(params) {
          if (params.data._orgsCount && params.data._orgsCount > 0) return `${$tt("Структур")}: ${params.data._orgsCount}`;
          else return `${$tt("Структур не обрано")}`;
        },
      },
      {
        headerName: $tt("Товари"),
        field: "sku",
        width: "270px",
        valueGetter: function(params) {
          if (params.data._skuCount && params.data._skuCount > 0) return `${$tt("Товарів")}: ${params.data._skuCount}`;
          else return `${$tt("Товарів не обрано")}`;
        },
      },
      {
        headerName: $tt("Примітка"),
        field: "notes",
        flex: 1,
      },
    ].map((column) => _.extend(column, { headerTooltip: column.headerName }));
  },
  methods: {
    initSelection() {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.deselectAll();
        if (this.selectedCorp) {
          var node = this.gridOptions.api.getRowNode(this.selectedCorp.id_corp);
          if (node) {
            node.setSelected(true);
            this.gridOptions.api.ensureNodeVisible(node, "middle");
          }
        }
      }
    },
    onGridReady() {
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("SelectRestrictsGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    onCommit() {
      if (this.selectedRestricts && this.selectedRestricts.length) {
        this.$emit("select-commit", { restricts: this.selectedRestricts, operation: this.operation });
        this.dialogShow = false;
      } else this.$message.error($tt("Будь ласка, оберіть хоча б один із рядків!"));
    },
    onCancel() {
      this.$emit("select-cancel");
      this.dialogShow = false;
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.loading = false;
    },
    onClose() {
      this.unobserveHeight();
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRestricts = rows;
      } else {
        this.selectedRestricts = null;
      }
    },
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { SelectRestrictsGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
  },
};
</script>

<style lang="scss" scope>
#SelectRestrictsDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 6px !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#SelectRestrictsDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 16px 16px 0 16px !important;
}

.flex_container {
  width: 100%;
}
</style>
